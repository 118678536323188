.blog-slider{
    background-image: url('../../public/image/program.png');
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    color: #fff;
    font-weight: bold;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: top;
    position: relative;
}
.blog-slider .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.blog-slider .center-area{
    z-index: 1;
}
.blog-slider h2{
    font-weight: bold;
    font-family: 'Roboto Slab',serif;
}

.blog-card-area .blog-card .blog-card-image img{
    border-radius: 10px;
}
.blog-card-area .blog-card .blog-card-text .post-meta ul li{
    list-style: none;
    color: #4f5655;
    font-size: 15px;
}
.blog-card-area .blog-card .blog-card-text a:hover{
    border-color: #7a64f2;
    color: #7a64f2;
}
.blog-card-area .blog-card .blog-card-text a{
    transition: all 0.2s ease-in-out;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #D4D4D4;
    color: #4f5655;
}
.blog-card-area .blog-card .blog-card-text p{
    color: #4f5655;
    padding: 10px 0 0 0;
}
.blog-card-area .blog-card .blog-card-text h4{
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    color: #4f5655;
    margin: 0;
    padding: 10px 0;
}
.blog-card-area .blog-card{
    padding:  15px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #babbbb;
}
.blog-card-area{
    padding: 80px 0px 80px 0px;
}