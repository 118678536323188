.contact-page-banner{
    background-color: #8CB23F;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.contact-left{
    background-image: url('../../public/image/contact-bg.png');
    background-repeat: no-repeat;
    background-position: center;
}
.contact-right{
    background-image: url('../../public/image/contact-bg2.png');
    background-repeat: no-repeat;
    background-position: top center;
}