.publication-slider{
    background-image: url('../../public/image/publication.png');
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    color: #fff;
    font-weight: bold;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% ;
}
.publication-slider .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.publication-slider .center-area{
    z-index: 1;
}
.publication-slider h2{
    font-weight: bold;
    font-family: 'Roboto Slab',serif;
}

.publication-section-header{
    margin: 45px 0px 0px 0px;
}
.publication-section-title{
    margin: 50px 0;
}
.publication-section-title span{
    font-family: "Playfair Display", Sans-serif;
    font-weight: 700;
    font-style: italic;
    color: #EA6B5B;
}
.publication-section-title p{
    color: #585367;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 60%;
    margin: 0 auto;
}
.publication-section-title h2::after{
    height: 4px;
    content: '';
    width: 100px;
    background: linear-gradient(90deg, #EA6B5B 0%, #EA6B5B 100%);
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.publication-section-title h2{
    color: #084C61;
    position: relative;
    margin: 0px 0px 10px 0px;
    font-family: "Archivo", Sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    letter-spacing: -1px;
    padding: 20px 0;
    margin: 0 0 30px 0;
}

.card-area .row{
    margin: 0 0 60px 0;
}
.card-area .card-text{
    padding: 20px 20px 20px 20px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #107547;
    border-radius: 50px 0px 50px 0px;
    margin: 30px 0;
}
.card-area .card-title{
    font-family: "Inter", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.1em;
    color: #14261c;
    text-transform: capitalize;
}

.card-area span{
    color: #58468c;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
}
.card-area .card-link h6{
    padding: 0px 0px 15px 0px;
    color: #292929;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}
.card-area .card-link .card-text3{
    color: #616161;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    line-height: 26px;
}
.card-area .card-link{
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #a5a5a5;
    border-radius: 5px 5px 5px 5px;
    padding: 10px 0;
}
.card-area .card-text2{
    font-family: "Roboto", Sans-serif;
    font-weight: 400;
    line-height: 26px;
    margin: 0px 0px 20px 0px;
}
.card-area .card-title2{
    color: #292929;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    margin: 0px 0px 025px 0px;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 700;
}
.card-area .btn-reade-more:hover{
    background-color: #084C61;
    color: white;
}
.card-area .btn-reade-more{
    padding: 17px 37px 17px 37px;
    font-family: "Roboto", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    background-color: #4f5fcd;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #ffffff;
    border-radius: 10px;
    color: white;
}

.card-area img{
    border-radius: 100px 0px 0px 0px;
}

.publication-card-area .section-card-title h3{
    color: #292929;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    margin: 0px 0px 060px 0px;
    font-family: "Roboto", Sans-serif;
    font-size: 36px;
    font-weight: 700;
}
.publication-card-area .section-card-title span{
    color: #333333;
    font-family: "Roboto", Sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0px 0px 10px 0px;
}
.publication-card-area .section-card-title{
    text-align: center;
}
.publication-card-area .pablication-card .card-content-area {
    padding: 20px 0 0 0;
}
.publication-card-area .pablication-card .card-content-area a:hover{
    color: #ffffff;
    background-color: #86B035;
}
.publication-card-area .pablication-card .card-content-area a{
    padding: 14px 20px 14px 20px;
    color: #107547;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f7f9fb;
    border-radius: 5px 5px 5px 5px;
}
.publication-card-area .pablication-card .card-content-area p{
    margin: 0px 0px 14px 0px;
    font-family: "Roboto", Sans-serif;
    font-weight: 400;
    line-height: 26px;
    color: #6e6e6e;
}
.publication-card-area .pablication-card .card-content-area h4{
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}
.publication-card-area .pablication-card{
    background-color: #ffffff;
    padding: 30px 30px 30px 30px;
    box-shadow: -1.21px 6.894px 15px 0px rgba(0, 0, 0, 0.07);
    margin: 0 0 30px 0;
}
.publication-card-area{
    padding: 80px 0px 80px 0px;

}