.program-slider{
    background-image: url('../../public/image/program.png');
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    color: #fff;
    font-weight: bold;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: top;
    position: relative;
}
.program-slider .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.program-slider .center-area{
    z-index: 1;
}
.program-slider h2{
    font-weight: bold;
    font-family: 'Roboto Slab',serif;
}

.program-card-box{
    background:#107547; 
    color:white;
    border-radius: 5px;    
}

.program-card-box:hover{
    background:#6FA338; 
    color:white;
    border-radius: 5px;
    /* slow effect  */
    transition: 0.5s;
    p{
        color:#FFFFFF;
    }
    .divider{border-block-start: 2px solid #FFFFFF; transition: 2s; }
}
.program-card-box p{
    margin: 0 0 5px 0;
    color: #6FA338;
}
.program-card-box a{
    font-size: 1.2rem;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    text-align: center;
}
.program-card-box .divider{
    text-align: center;
    padding-block-start: 5px;
    padding-block-end: 5px;
    width: 30%;
    margin: 10px auto 0px auto;
    border-block-start: 2px dashed #6FA338;
}
.program-card-box{
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #00000021;
    text-align: center;
    padding: 41px 25px 50px 25px;
    margin: 0px 5px;
}

.card-section .image-title{
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #4f5655;
}
.card-section{
    padding: 40px 0px 80px 0px;
    border-top: 1px solid #d3d3d3;
}


.card-section .row p{
    color: #434444;
}
.card-section .row h5{
    color: #14261c;
    font-size: 1.2rem;
}
.card-section .row h2{
    font-size: 3.3333333333333rem;
    font-family: 'Roboto Slab', serif;
    line-height: 1.1em;
    text-transform: capitalize
}
.card-section .row{
    margin: 35px 0;
}