.about-slider{
    background-color: #8CB23F;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    color: #fff;
    font-weight: bold;
}

.about-page-area{
    background-image: url('../../public/image/about-page-bg.png');
    /* background-repeat: no-repeat; */
}
.section-page-area{
    background-image: url('../../public/image/Inspire.png');
    /* background-repeat: no-repeat; */
}

.overlay-image{
    position: absolute;
    bottom: -97px;
    right: 5%;
    z-index: 9;
    @media screen and (max-width: 620px) {
        width: 30%;
        height: auto;
        bottom: -40px;
    }
}
.swiper-button-prev::after, .swiper-button-next::after{
    color: #87AF35;
}
/* .swiper-button-prev::after{
    left: 30px !important;
} */
