@import url('css/header.css');
@import url('css/governance.css');
@import url('css/contact.css');
@import url('css/about.css');
@import url('css/program.css');
@import url('css/publication.css');
@import url('css/blog.css');

a{ text-decoration: none !important; }

/* global class  */
.adi-bg-primary{ background-color: #107547; }
.adi-bg-secondary{ background-color: #87AF35; }
.adi-text-primary{ color: #107547; }
.adi-text-secondary{ color: #87AF35; }
.adi-btn-primary{ background-color: #107547 !important; color: #fff !important; }
.adi-btn-secondary{ background-color: #87AF35 !important; color: #fff !important;  }
.adi-btn-secondary:hover{ opacity: 0.8; transition-duration: 2s; }

/* Global styles */
body {
  margin: 0;
  padding:0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header style */
.header {
  color: #686E6D;
}

.header .icon img {
  width: 100px; 
  height: 100px;
}

.header .topbar-grid {
  margin-top: 30px;
}

.header .topbar-grid a {
  font-weight: 700;
  color: #8CB23F;
  text-decoration: none; 
}

.header .topbar-grid a:hover {
  opacity: 0.8;
}

.header .topbar-grid .font-icon {
  font-size: 40px;
  color: #C2CBD2;
  margin-right: 10px;
  margin-top: -15px;
}

/* homepage style  */
.call-2-action-1{
  background-color: #8CB23F;
  color: #fff;
}


/* .footer-style  */
.footer{
  background-color: #107547;
}
.footer li{ list-style:none; }
.footer a{
  color: #fff; 
  text-decoration: none;
}
.footer a:hover{ opacity: 0.8; }
.copyright{
  border-top: 1px solid #8CB23F;
}

.about-section .p{
  color: #4f5655;
}
.about-section .display-3{
  color: #107547;
  font-size: 40px;
  text-transform: uppercase;
  font-family: 'Roboto Slab', serif;
}
.about-section{
  /* background-image: url('../public/image/about-bg.png'); */
  background-position: right;
  background-repeat: no-repeat;
  padding: 20px 0;
}

.home-slider{
  background-image: url('../public/image/about-bg.png');
  background-position: right;
  background-repeat: no-repeat;
}