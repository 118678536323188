/* Main menu styles */
.main-menu {
  background-color: #107547;
  border-top: 3px solid #87AF35;
  padding: 10px 0;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

ul.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  position: relative;
}

/* .menu-item {
  position: relative;  
} */

.menu-item a {
  text-decoration: none;
  font-size: 16px;
  /* background-color: #87AF35; */
  padding: 12px 18px;
  color: #fff;
  font-weight: bold;
}
.menu-item .active{
  background-color: #87AF35;
}
.menu-item a:hover {
  opacity: 0.8;
}


/* Mega menu styles */
/* .mega-menu {
    position: relative;
  } */
  
  .mega-menu-content {
    display: none;
    position: absolute;
    left: 0; /* Center the mega menu */
    width: 100%; /* Set the width of the mega menu */
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    height: auto;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 999;
    
  }
  
  .mega-menu:hover .mega-menu-content {
    display: flex;
  }
  
  .mega-menu-row {
    display: flex;
    justify-content: space-between;
  }
  
  .mega-menu-column {
    padding: 10px;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #EDEDED;
  }
  
  .mega-menu-column h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .mega-menu-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .mega-menu-column ul li {
    margin-bottom: 8px;
  }
  
  .mega-menu-column ul li a {
    text-decoration: none;
    color: #686E6D;
    font-size: 14px;
  }
  
  .mega-menu-column ul li a:hover {
    text-decoration: underline;
    color: #87AF35;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .mega-menu-content {
      flex-direction: column;
      width: 100%; /* Full width for mobile */
      left: 0;
      transform: none;
    }
  
    .mega-menu-column {
      width: 100%;
    }
  }
  
  .social-links{
    text-align: right;
  }
  .social-links a{
    color: #fff;
    margin-right: 10px;
  }
  .social-links a:hover{
    opacity: 0.8;
  }

    /* Responsive styles */
    @media (max-width: 990px) {
      ul.menu {
        display: block;
        position: absolute;
        left: 0px;
        width: 280px;
        background-color: #0d4129;
        z-index: 999;
        padding: 20px 0;
        height: 100vh;
        top: 0;
        transition: all 0.2s ease-in-out;
        transform: translateX(-100%);
      }
      ul.menu.active {
        transform: translateX(0);
      }
      .menu li {
        margin: 18px 20px;
      }
      .menu li a{
        padding: 12px 20px;
      }
    }