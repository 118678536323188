.governance-slider{
    background-image: url('../../public/image/governance.png');
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 3rem; */
    color: #fff;
    font-weight: bold;
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: top;
    position: relative;
}
.governance-slider .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
}
.governance-slider h2{
    z-index: 1;
    font-weight: bold;
    font-family: 'Roboto Slab',serif;
}

.governance-section strong {
    color: #14261c;
    font-size: 1.6rem;
}
.governance-section .p{
    padding: 20px 0px 20px 0px;
    color: #4d5651;
}
.governance-section .display-3{
    font-size: 36px;
    line-height: 1.1em;
}

.section-header span{
    color: rgba(0, 0, 0, 0.5);
    font-size: 22px;
    letter-spacing: 5.8px;
    margin: 20px 0px 20px 0px;
}
.section-header .governance-title{
    font-size: 40px;
    font-family: 'Roboto Slab', serif;
    line-height: 1.1em;
    text-transform: capitalize;
}

.governance-card-area .card-body .card-content{
    font-family: "Philosopher", Sans-serif;
    font-size: 18px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, .5);
}
.governance-card-area .card-body h4{
    font-family: "Philosopher", Sans-serif;
    font-size: 22px;
    color: white;
    margin-top: 20px;
}
.governance-card-area .card-body{
    padding: 20px 20px 20px 20px;
    border-radius: 5px 5px 5px 5px;
}